<template>
  <div class="flex items-center justify-between group">
    <div class="flex items-center space-x-2">
      <img
        @click="showImage(variant.ImageThumbnailUrl, variant.id)"
        :src="variant.ImageThumbnailUrl"
        alt="Image"
        class="
          w-8
          h-8
          object-contain object-center
          flex-shrink-0
        "
        :class="isLinked ? '' : 'cursor-pointer'"
      />
      <div class="flex flex-col space-y-1.5">
        <div class="flex gap-1 items-center">
          <p
            class="flex block text-sm leading-none text-brand-primary font-semibold pr-4"
          >
            {{ variant.opt1 != null ? variant.opt1 : variant.name }}
            <span
              v-if="isLoggedIn && variant.banned == 'true'"
              class="n-icon"
              :content="
                `This product cannot be shipped to ${getSelectedAddress.state}`
              "
              v-tippy="{ placement: 'bottom-end' }"
            >
              <Icon name="triangle-warn" class="w-4 h-4" />
            </span>
          </p>
        </div>
        <div class="flex items-center space-x-2">
          <span
            v-if="isLoggedIn"
            class="text-xs leading-none text-brand-secondary font-normal"
          >
            {{
              variant.price == 0 || variant.price == null
                ? null
                : "$" + getPrice(variant, qty, bulk)
            }}
          </span>
          <Badge name="New" v-if="isLoggedIn && isNew()" variant="primary" />
          <Badge
            :name="variant.variant_badge ? variant.variant_badge.badge.title : 'Clearance'"
            v-else-if="isLoggedIn && variant.is_clearance"
          />
          <Badge
            :name="variant.variant_badge ? variant.variant_badge.badge.title : 'Discontinued'"
            v-else-if="isLoggedIn && variant.is_discontinued"
          />
        </div>
        <span
          v-if="
            (variant.stockLevelAbsolute <= 0 ||
              variant.price == null ||
              variant.price == 0) &&
              isLoggedIn &&
              variant.has_notification &&
              variant.has_notification.length > 0
          "
          class="w-full inline-block md:hidden uppercase text-tiny font-normal text-brand-gray tracking-wider leading-none"
          >{{
            text && text.toLowerCase().includes(`don't`)
              ? "Notification Set"
              : variant.first_landed_date == null
              ? "coming soon"
              : "out of stock"
          }}</span
        >
        <span
          v-else-if="
            isLoggedIn &&
              (variant.stockLevelAbsolute <= 0 ||
                variant.price == null ||
                variant.price == 0)
          "
          class="w-full inline-block md:hidden uppercase text-tiny font-normal text-brand-gray tracking-wider leading-none"
          >{{
            data && data.toLowerCase().includes(`don't`)
              ? "Notification Set"
              : variant.first_landed_date == null
              ? "coming soon"
              : "out of stock"
          }}</span
        >
      </div>
    </div>
    <div
      v-if="
        isLoggedIn &&
          variant.banned == 'false' &&
          !product.licenseRequired &&
          variant.price != null &&
          variant.price != 0
      "
      class="flex items-center space-x-3 flex-shrink-0"
    >
      <PlusMinusInput
        :id="variant.id"
        v-on:getQuantity="updateCart"
        v-model:modelValue="qty"
        :min="0"
        :max="variant.MaxOrderQty"
        :stock="variant.stockLevelAbsolute"
        v-if="variant.stockLevelAbsolute > 0"
        :tickIcon="true"
        @removeItem="removeItem"
      />
    </div>

    <div
      v-if="
        (variant.stockLevelAbsolute <= 0 ||
          variant.price == null ||
          variant.price == 0) &&
          isLoggedIn &&
          variant.banned == 'false' && 
          !product.licenseRequired
      "
      class="flex items-center space-x-3 flex-shrink-0"
    >
      <button
        v-if="variant.has_notification && variant.has_notification.length > 0"
        :id="variant.id"
        @click="doNotNotify(variant)"
        class="notify-bell"
        :class="{ undo: text && text.toLowerCase().includes(`don't`) }"
      >
        <span
          class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider"
          >{{
            text && text.toLowerCase().includes(`don't`)
              ? "Notification Set"
              : variant.first_landed_date == null
              ? "coming soon"
              : "out of stock"
          }}</span
        >
        <div
          class="n-icon"
          v-tippy="{
            content:
              text && text.toLowerCase().includes(`don't`)
                ? 'You will be notified when this product is available.'
                : 'Click here to be notified when this product is available.',
            placement: 'bottom-end',
          }"
        >
          <Icon
            :name="
              text && text.toLowerCase().includes(`don't`)
                ? 'bell-off'
                : 'bell-ring'
            "
          />
        </div>
      </button>
      <button
        v-else
        @click="Notify(variant)"
        :id="variant.id"
        class="notify-bell"
        :class="{ undo: data && data.toLowerCase().includes(`don't`) }"
      >
        <span
          class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider"
          >{{
            data && data.toLowerCase().includes(`don't`)
              ? "Notification Set"
              : variant.first_landed_date == null
              ? "coming soon"
              : "out of stock"
          }}</span
        >
        <div
          class="n-icon"
          v-tippy="{
            content:
              data && data.toLowerCase().includes(`don't`)
                ? 'You will be notified when this product is available.'
                : 'Click here to be notified when this product is available.',
            placement: 'bottom-end',
          }"
        >
          <Icon
            :name="
              data && data.toLowerCase().includes(`don't`)
                ? 'bell-off'
                : 'bell-ring'
            "
          />
        </div>
      </button>
    </div>
    <div
      v-else-if="!isLoggedIn || variant.banned == 'true' || product.licenseRequired" 
      class="locked-holder"
    >
      <div
        class="n-icon"
        v-tippy="{
          content:getContent(),
          placement: 'bottom-end',
        }"
      >
        <Icon name="prohibited" class="w-6 h-6" />
      </div>
    </div>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import debounce from "lodash/debounce";
import Notifications from "@/modules/Notifications";
import axios from "../../../plugins/axios";
import { mapGetters } from "vuex";

export default {
  name: "Item",
  props: [
    "variant",
    "isLoggedIn",
    "bulk",
    "productsQty",
    "catRank",
    "brandRank",
    "product",
    "isLinked",
  ],
  mixins: [Mymixin],
  watch: {
    cart: {
      handler: "mountCartData",
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      axios,
      qty: 0,
      preQty: 0,
      // cart: this.$store.getters.cart,
      text: "Don't Notify me",
      data: "Notify me",
    };
  },
  computed: {
    ...mapGetters(["cart", "getSelectedAddress"]),
  },
  methods: {
    mountCartData() {
      if (this.isLoggedIn) {
        if (this.cart.itemsByCat?.length > 0) {
          this.cart.itemsByCat.forEach((element) => {
            element.items.forEach((item) => {
              if (item.variantId == this.variant.id) {
                // console.log("update value testing",item.variantId,"==", this.variant.id, item.qty )
                this.qty = item.qty;
                this.preQty = item.qty;
              }
            });
          });

          // For Update Variant When Any Item Deleted From Cart
          if (this.cart.deleted_variant_id == this.variant.id) {
            this.qty = 0;
          }
        } else {
          this.qty = 0;
        }
      }
    },

    // emit an event
    showImage(val, id) {
      this.$emit("image-click", val, id);
    },

    // Update Cart Data in Store
    updateCart: debounce(function(d) {
      let list = this.$store.getters.getList;
      let [qty, add] = d;
      let data = {
        variant_id: this.variant.id,
        qty: qty,
        segmentQty: add ? qty - this.preQty : this.preQty - qty,
        axios: this.axios,
        route: this.$route,
        add,
        catRank: this.catRank,
        brandRank: this.brandRank,
        list_id: list.list_id ?? this.product.brand,
        list_type: list.list_type ?? this.$route.path.split("/")[1],
        position: list.position ?? 0,
      };
      this.preQty = qty;
      if (qty === 0) {
        this.$store.dispatch("removeFromCart", data);
      } else {
        this.$store.dispatch("UpdateCart", data);
      }
    }, 200),

    Notify(id) {
      if (this.data == "Notify me") {
        this.notifyme(id, this.product);
        this.data = "Don't Notify me";
      } else {
        this.data = "Notify me";
        this.deleteNotify(id, this.product);
      }
    },
    doNotNotify(id) {
      if (this.text == "Don't Notify me") {
        this.deleteNotify(id, this.product);
        this.text = "Notify me";
      } else {
        this.text = "Don't Notify me";
        this.notifyme(id, this.product);
      }
    },
    getPrice(val, qty, bulk) {
      val.qty = qty;
      val.bulk = bulk ? bulk : [];
      if (bulk && this.cart.bulkTags && val.price > val.min_price) {
        // const dis = bulk.filter((r) => this.productsQty >= r.min_quantity);
        const dis = bulk.filter(
          (r) =>
            parseInt(this.cart.bulkTags[this.product.bulk_tag]) >=
            r.min_quantity
        );
        const discount_row = dis[dis.length - 1];
        let discount_price = discount_row
          ? (
              val.price -
              (val.price * discount_row.discount_percentage) / 100
            ).toFixed(2)
          : Number(val.price).toFixed(2);
          if(discount_price < val.min_price){
            if(val.price > val.min_price){
              discount_price = Math.max(discount_price, val.min_price);
            }
          }
        return Number(discount_price).toFixed(2);
      } else {
        return Number(val.price).toFixed(2);
      }
    },
    isNew() {
      const date = new Date();
      const firstLandedDate = new Date(this.variant.first_landed_date);
      const timeDifference = date.getTime() - firstLandedDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      return daysDifference >= 0 && daysDifference <= 30;
    },
    removeItem() {
      let data = {
        variant_id: this.variant.id,
        qty: 0,
        segmentQty: this.qty,
        axios: this.axios,
        route: this.$route,
      };
      this.preQty = 0;
      this.$store.dispatch("removeFromCart", data);
    },
    getContent() {
      return this.variant.banned == "true"
        ? "This product cannot be shipped to your selected address."
        : this.product.licenseRequired
        ? "This product requires a license to purchase."
        : null;
    },
  },
  setup() {
    const { notify, notifyme, deleteNotify } = Notifications();
    return {
      notify,
      notifyme,
      deleteNotify,
    };
  },
};
</script>
<style lang="scss" scoped>
.locked-holder {
  @apply w-48 flex items-center justify-end space-x-4 relative;

  &:before {
    content: "";
    @apply absolute w-20 right-16 h-0.5 bg-theme-body bg-opacity-40;
  }
}
.notify-bell {
  @apply flex items-center space-x-3 uppercase text-xs tracking-tight;
  .n-icon {
    @apply inline-flex items-center justify-center w-10 h-10 bg-brand-red text-white rounded;

    svg {
      @apply w-5 h-5;
    }
  }
  &.undo {
    .n-icon {
      @apply bg-theme-secondary;
    }
  }
}
</style>
