<template>
  <div class="prod-brand " :class="{ 'is-loading': isLoading }">
    <div class="prod-brand-info">
      <div class="brand-img ">
        <img :src="img" v-if="!isLoading" class="max-w-full" />
      </div>
      <div class="flex flex-col">
        <router-link :to="url" v-if="!isLoading">
          <p class="brand-name">{{ name }}</p>
        </router-link>
        <p class="brand-name" v-else>XXXX</p>
      </div>
    </div>
    <router-link :to="url" v-if="!isLoading">
      <button class="brand-btn">
        View All
      </button></router-link
    >
  </div>
</template>

<script>
export default {
  name: "BrandCard",
  props: {
    img: String,
    name: String,
    url: String,
    isLoading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.prod-brand {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  @apply rounded-lg bg-white overflow-hidden;

  .prod-brand-info {
    @apply flex items-center space-x-4 px-3 py-3;

    .brand-img {
      @apply flex justify-center items-center w-16 h-16 rounded-full border border-theme-border border-opacity-50 flex-shrink-0;
      padding: 2px;
    }

    .brand-name {
      @apply text-base font-semibold text-theme-secondary;
    }
  }

  button.brand-btn {
    @apply w-full bg-brand-ash bg-opacity-20 text-xs font-normal uppercase leading-none py-3 text-brand-body hover:text-brand-secondary;
  }

  &.is-loading {
    .brand-name {
      @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md w-36;
    }
    .brand-img {
      @apply bg-gray-100 animate-pulse border-transparent;
      img {
        @apply opacity-0;
      }
    }
  }
}
</style>
