<template>
  <div class="prod-info is-loading" v-if="isLoading">
    <h3 class="loader">Product Sub title</h3>
    <p class="loader">Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
    <ul>
      <li class="loader">Test list 1</li>
      <li class="loader">Test list 2 testing</li>
      <li class="loader">Test list 3 testing 3</li>
      <li class="loader">Test list 1</li>
    </ul>
    <div class="w-full">
      <div class="loader w-full max-w-sm h-16 mt-4"></div>
    </div>
  </div>
  <Disclosure
    as="div"
    v-else-if="showDisclosure"
    class="prod-info disclosure"
    v-slot="{ open }"
  >
    <DisclosureButton
      class="flex items-center justify-between px-6 py-3  w-full"
    >
      <p class="flex items-center text-base text-theme-secondary font-medium">
        Product Information
      </p>
      <ChevronUpIcon
        :class="!open ? 'transform rotate-180' : ''"
        class="w-5 h-5 text-theme-body"
      />
    </DisclosureButton>
    <DisclosurePanel>
      <div
        class="prod-text prose max-w-none font-light text-brand-primary px-6 pb-6"
      >
        <p v-html="description"></p>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <template v-else>
    <div
      class="prod-text prose max-w-none font-light text-brand-primary p-6"
      id="prodDesc"
      :class="{ 'max-prod-height': showReadMore }"
    >
      <p v-html="description"></p>
    </div>
    <div
      class="readmore-block"
      v-if="showReadMore && description"
      @click="showReadMore = false"
    >
      <button>Read More</button>
    </div>
  </template>
</template>

<script>
import { ChevronUpIcon } from "@heroicons/vue/solid";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
  name: "ProductInfo",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    description: {
      type: String,
      default: "",
    },
    isMinimized: { type: Boolean, default: false },
  },
  setup(props) {
    const showReadMore = ref(false);

    const isLoaded = computed(() => props.isLoading);

    const showDisclosure = computed(() => props.isMinimized);

    const checkInfoHeight = () => {
      const el = document.querySelector("#prodDesc");
      if (!el) return;
      nextTick(() => {
        const difference = el.clientHeight - 65 - 600;
        console.log(difference);
        if (difference < 200) {
          el.style.maxHeight = "unset";
          showReadMore.value = false;
        } else {
          showReadMore.value = el.clientHeight - 65 > 600;
        }
      });
    };

    onMounted(() => {
      setTimeout(() => {
        checkInfoHeight();
      }, 100);
    });

    watch(isLoaded, () => {
      setTimeout(() => {
        checkInfoHeight();
      }, 100);
    });

    watch(showDisclosure, () => {
      setTimeout(() => {
        checkInfoHeight();
      }, 100);
    });

    return {
      showReadMore,
      showDisclosure,
    };
  },
};
</script>

<style lang="scss" scoped>
.prod-info {
  @apply pb-4 lg:pt-4;

  &.disclosure {
    @apply p-0;
  }

  &.is-loading {
    @apply flex flex-col gap-3 mt-6;
    .loader {
      @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md;
    }
    ul {
      @apply flex flex-col w-full gap-3;
    }
  }
}

.max-prod-height {
  max-height: 600px;
  overflow: hidden;
}
.readmore-block {
  @apply relative flex items-end justify-center w-full h-16 bg-gradient-to-t from-white -mt-16 z-20;
  button {
    @apply bg-white text-theme-body-light border border-theme-border rounded-md leading-none px-6 py-2;
  }
}
</style>
